const ENVS: { [key: string]: string } = {
	local: 'http://localhost:3000',
	dev: 'https://ctp.shotlandtst.golf',
	staging: 'https://ctp.shotlandint.golf',
	prod: 'https://ctp.shotlander.io',
};

// READ : for local testing, if you don't want to connect to localhost and would rather point to a different environment url, change this string to whichever environment you want (dev, staging, or prod)
// KEEP IN MIND : if you do this, make sure to start the app with the corresponding environment api key (REACT_APP_API_KEY={key_goes_here} npm run start) or you will get 401 errors
// ALSO : make sure to change this variable back to 'local' before committing
let stage = 'local';

if (
	process.env.REACT_APP_STAGE &&
	Object.keys(ENVS).includes(process.env.REACT_APP_STAGE)
) {
	stage = process.env.REACT_APP_STAGE;
}

const SPORTS_EXPERIENCES_URL = ENVS[stage];

export default {
	SPORTS_EXPERIENCES_URL,
};
