import React, { useEffect, useRef, useState } from 'react';
import { Location } from 'react-router-dom';
import CONFIG from './service-config';
import './App.scss';

const App = () => {
	const { SPORTS_EXPERIENCES_URL } = CONFIG;

	const frameRef = useRef<HTMLIFrameElement>(null);

	const [frameUrl] = useState<string>(
		`${SPORTS_EXPERIENCES_URL}${window.location.pathname}`
	);

	// need to send domain and api key to main app so that it knows where to send messages back to
	const frameLoaded = () => {
		// this timeout is needed to give the main application enough time to set up the event listener to listen for this message
		setTimeout(() => {
			frameRef.current?.contentWindow?.postMessage(
				{
					domain: window.location.origin,
					pathname: window.location.pathname,
					search: window.location.search,
					apiKey: process.env.REACT_APP_API_KEY,
				},
				SPORTS_EXPERIENCES_URL
			);
		}, 250);
	};

	window.onpopstate = () => {
		frameLoaded();
	};

	// listen for the iframe content to pass back parameter details so we can update the global url
	useEffect(() => {
		const handler = (event: MessageEvent) => {
			if (event.origin.startsWith(SPORTS_EXPERIENCES_URL)) {
				// If response is type string, update document title. Otherwise, update url params
				if (typeof event.data === 'string' || event.data instanceof String) {
					document.title = `${event.data}`;
				} else {
					const data = event.data as Location;
					// check if data is correct object
					if (data) {
						window.history.pushState(
							null,
							document.title,
							`${data.pathname}${data.search}`
						);
					}
				}
			}
		};

		window.addEventListener('message', handler);

		return () => window.removeEventListener('message', handler);
	}, []);

	return (
		<iframe
			title='Golf Experiences App'
			src={frameUrl}
			key={frameUrl}
			className='App'
			ref={frameRef}
			allowFullScreen
			onLoad={frameLoaded}
		/>
	);
};

export default App;
